import type { RouteRecordRaw } from 'vue-router'
// @ts-ignore
import featuredImageImportView from '@/views/project/tools/featuredImageImportView.vue'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const featuredImageImport: RouteRecordRaw = {
  path: 'featuredImageImport',
  name: 'featuredImageImport',
  component: featuredImageImportView,
  meta: {
    breadcrumb: 'featuredImageImport',
    menu: {
      key: 'featuredImageImport',
      icon: 'mdi-image-album',
      name: 'featuredImageImport'
    },
    scopes: [KeycloakScope.UNIT_LIST, KeycloakScope.UNIT_UPDATE]
  }
}

export default featuredImageImport
