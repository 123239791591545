<template>
  <vis-loader type="list-item-avatar" :loading="images.isFetching">
    <v-container>
      <v-row>
        <v-col cols="12"> {{ label }}</v-col>
      </v-row>
      <v-row class="bg-grey-lighten-4 pa-5">
        <div class="d-flex align-center">
          <div v-if="selectedImage">
            <v-hover v-slot="{ isHovering, props }" :key="model">
              <div v-bind="props" class="ma-1 pa-2 bg-white position-relative cursor-pointer">
                <v-img
                  :src="`${project.data!.assetRoot}/images/${selectedImage?.category}/${selectedImage?.filename}`"
                  height="100"
                  width="100"
                  cover
                  @click="openPreview(selectedImage as Image)"
                />
                <v-expand-transition>
                  <div
                    v-if="isHovering"
                    class="d-flex transition-fast-in-fast-out selected-image-hover-overlay position-absolute bottom-0 left-0 right-0"
                    style="height: 30%"
                  >
                    <v-btn
                      size="x-small"
                      variant="text"
                      color="error"
                      class="selected-image-delete-btn position-absolute"
                      @click="remove(model)"
                    >
                      {{ t('remove') }}
                    </v-btn>
                  </div>
                </v-expand-transition>
              </div>
            </v-hover>
          </div>

          <v-dialog v-model="dialog" transition="dialog-bottom-transition" fullscreen>
            <template v-slot:activator="{ props: activatorProps }">
              <div class="ma-1 pa-2 bg-white position-relative cursor-pointer">
                <div class="blank-image bg-grey display-inline-block">&nbsp;</div>
                <div
                  class="d-flex transition-fast-in-fast-out add-image-hover-overlay position-absolute bottom-0 left-0 right-0"
                  style="height: 100%"
                  v-bind="activatorProps"
                >
                  <v-btn
                    size="x-small"
                    :icon="model === 0 ? 'mdi-plus-thick' : 'mdi-pencil'"
                    class="selected-image-add-btn position-absolute"
                    :title="multiple ? t('addImages') : t('addOrUpdateImage')"
                  >
                  </v-btn>
                </div>
              </div>
            </template>

            <v-card>
              <v-toolbar color="primary">
                <v-btn icon="mdi-close" @click="dialog = false"></v-btn>

                <v-toolbar-title>{{ label }}</v-toolbar-title>

                <v-spacer></v-spacer>

                <v-toolbar-items>
                  <v-btn :text="t('done')" @click="dialog = false"></v-btn>
                </v-toolbar-items>
              </v-toolbar>
              <MediaGalleryView
                :preSelected="[selectedImageIds]"
                :multiple="multiple"
                :allowSelect="true"
                @onSelect="onSelect($event)"
                :category="category ? category : ProjectImagesCategory.ContentAssets"
              />
            </v-card>
          </v-dialog>
        </div>
      </v-row>
    </v-container>

    <v-dialog v-model="previewDialog" max-width="600">
      <div v-if="previewImage">
        <v-card class="mx-auto pb-10 align-center">
          <v-img
            :src="`${project.data!.assetRoot}/images/${previewImage.category}/${previewImage.filename}`"
            class="preview-dialog-image"
            contain
          />

          <v-card-title :title="previewImage.filename" class="mt-3"
            >{{ previewImage.filename }}
          </v-card-title>
          <v-card-subtitle> {{ previewImage.category }} </v-card-subtitle>

          <div class="close-btn position-absolute top-0 right-0">
            <v-btn size="x-small" icon="mdi-close" @click="closePreview()"></v-btn>
          </div>
        </v-card>
      </div>
    </v-dialog>
  </vis-loader>
</template>

<script setup lang="ts">
import MediaGalleryView from './../../views/project/settings/MediaGalleryView.vue'
import useImageStore from '@/stores/image'
import useProjectStore from '@/stores/project'
import { computed, ref, type ComputedRef } from 'vue'
import type Image from '@/types/image'
import { useI18n } from 'vue-i18n'
import { ProjectImagesCategory } from '@/types/image'

const { t } = useI18n()

const model = defineModel({ type: Number, default: 0 })
const { label, category } = defineProps<{ label: string; category?: ProjectImagesCategory }>()

const dialog = ref(false)

const { project } = useProjectStore()
const { images, listImages } = useImageStore()
images.data || listImages()

const selectedImageIds = computed(() => {
  return model.value
})

const multiple = false
const onSelect = (images: Array<Image>) => {
  if (images.length > 0) {
    model.value = images[0].id
    return
  }
  model.value = 0
}

const selectedImage: ComputedRef<Image | null> = computed(() => {
  const findImage = images.data?.find((image) => image.id === model.value)
  return findImage ? findImage : null
})

const remove = async (imageId: number) => {
  model.value = 0
}

const previewDialog = ref(false)
const previewImage = ref<Image | null>(null)
const openPreview = (image: Image) => {
  previewImage.value = image
  previewDialog.value = true
}

const closePreview = () => {
  previewImage.value = null
  previewDialog.value = false
}
</script>
<style scoped>
.selected-image-delete-btn,
.selected-image-add-btn {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.selected-image-hover-overlay,
.selected-add-hover-overlay {
  background-color: rgba(0, 0, 0, 0.8);
}

.blank-image {
  width: 100px;
  height: 100px;
}

.preview-dialog-image {
  height: 70vh;
  width: auto;
}
</style>
