<template>
  <vis-loader
    :loading="
      unit.isFetching ||
      buildings.isFetching ||
      statuses.isFetching ||
      fields.isFetching ||
      unitUsers.isFetching
    "
  >
    <vis-sheet>
      <h4>{{ $t('general') }}</h4>
      <unit-form
        :project="project.data!"
        :statuses="statuses.data!"
        :fields="fields.data!"
        :buildings="buildings.data!"
        :unit="unit.data!"
        @submit="onSubmit"
      />
    </vis-sheet>
    <vis-sheet>
      <h4>{{ $t('virtualTour') }}</h4>
      <vis-loader :loading="!interiorOptions.data || interiorOptions.isFetching">
        <virtual-tour
          ref="virtualTour"
          :project="project.data!"
          :unit="unit.data!"
          :interior-options="interiorOptions.data!"
          :interior-id="currentInteriorId"
          :version="
            unitVersions.data && unitVersions.data.length > 0
              ? unitVersions.data[0].version
              : undefined
          "
          @on-snap-shot="onSnapShot"
          @on-view-update="onViewUpdate"
        />
      </vis-loader>
      <div v-if="isAdmin" class="py-2 w-100">
        <vis-loader
          :loading="
            interiorOptions.isFetching ||
            !apartmentData.data ||
            !thumbnailData.data ||
            apartmentData.isFetching ||
            thumbnailData.isFetching
          "
        >
          <unit-virtual-tour-table
            :project="project.data!"
            :unit="unit.data!"
            :interiorOptions="validInteriorOptions"
            :apartmentData="apartmentData.data!"
            :thumbnailData="thumbnailData.data!"
            :current-interior-id="currentInteriorId"
            :current-camera-id="currentCameraId"
            @camera="goToCamera"
            @delete="deleteThumbnail"
            @reorder="reorderThumbnails"
          />
        </vis-loader>
      </div>
    </vis-sheet>
    <vis-sheet>
      <h4>{{ $t('accessPermissions') }}</h4>
      <user-access-form :users="unitUsers.data!" @grant="onGrant" @revoke="onRevoke" />
    </vis-sheet>
  </vis-loader>
  <v-overlay
    class="d-flex justify-center align-center"
    disabled
    persistent
    :modelValue="!!snapshotSequence"
  >
    <v-card class="pa-4">{{ $t('generatingSnapshots') }}...</v-card>
  </v-overlay>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import useRouteId from '@/composables/useRouteId'
import useUnitStore from '@/stores/unit'
import UnitForm from '@/components/forms/UnitForm.vue'
import useSubmitThenRoute from '@/composables/useSubmitThenRoute'
import UserAccessForm from '@/components/forms/UserAccessForm.vue'
import UnitVirtualTourTable from '@/components/tables/UnitVirtualTourTable.vue'
import useFieldStore from '@/stores/field'
import useStatusStore from '@/stores/status'
import useUnitPermissionStore from '@/stores/unitPermission'
import type User from '@/types/user'
import type Unit from '@/types/unit'
import VirtualTour from '@/components/players/VirtualTour.vue'
import useProjectStore from '@/stores/project'
import useInteriorOptionStore from '@/stores/interiorOption'
import useAuthStore from '@/stores/auth'
import type InteriorOption from '@/types/interiorOption'
import useBuildingStore from '@/stores/building'
import useUnitVersionStore from '@/stores/unitVersion'

const { isAdmin } = useAuthStore()

const { project } = useProjectStore()

const { interiorOptions, listInteriorOptions } = useInteriorOptionStore()

const {
  unit,
  apartmentData,
  thumbnailData,
  listApartmentData,
  listThumbnailData,
  listUnits,
  readUnit,
  updateUnit,
  deleteUnitThumbnail,
  reorderUnitThumbnails,
  updateUnitThumbnail
} = useUnitStore()

const { t } = useI18n()

const { fields, listFields } = useFieldStore()
fields.data || listFields()

const { statuses, listStatuses } = useStatusStore()
statuses.data || listStatuses()

const { buildings, listBuildings } = useBuildingStore()
buildings.data || listBuildings()

const { unitVersions, listUnitVersions } = useUnitVersionStore()

const currentInteriorId = ref<string>()
const currentCameraId = ref<string>()
const snapshotSequence = ref<Array<{
  file: File | null
  camera: string | null
  rotation: string | null
  interiorOption: string
}> | null>(null)

const onSubmit = useSubmitThenRoute(
  async (unit: Partial<Unit>) => {
    await updateUnit(unit)
  },
  { name: 'units' },
  () => listUnits()
)

const { unitUsers, listUnitUsers, grantUnitPermissions, revokeUnitPermissions } =
  useUnitPermissionStore()

const onGrant = async (email: string) => {
  await grantUnitPermissions(email)
  listUnitUsers()
}

const onRevoke = async (user: User) => {
  await revokeUnitPermissions(user.email)
  listUnitUsers()
}

const validInteriorOptions = computed(() => {
  const validIds: Array<string> = unit
    .data!.playerData.filter((i: any) => i.isValid)
    .map((i: InteriorOption) => i.id)
  return interiorOptions.data!.filter((i) => validIds.includes(i.guid))
})

const virtualTour = ref<InstanceType<typeof VirtualTour>>()

const onSnapShot = async (file: File, rotation: string, interiorId: string, cameraId: string) => {
  if (isAdmin) {
    if (!snapshotSequence.value) {
      if (confirm(t('areYouSureYouWantToUpdateTheCurrentCameraSettings'))) {
        snapshotSequence.value = validInteriorOptions.value.map((i) => ({
          file: null,
          camera: null,
          interiorOption: i.guid,
          rotation: null
        }))
        currentInteriorId.value = snapshotSequence.value[0].interiorOption
        setTimeout(() => {
          virtualTour.value!.takeSnapshot()
        }, 7500)
      }
    } else {
      const ssIndex = snapshotSequence.value.findIndex((i) => i.interiorOption === interiorId)
      if (ssIndex >= 0) {
        snapshotSequence.value[ssIndex].file = file
        snapshotSequence.value[ssIndex].rotation = rotation
        snapshotSequence.value[ssIndex].camera = cameraId
      }
      if (ssIndex + 1 < snapshotSequence.value.length) {
        currentInteriorId.value = snapshotSequence.value[ssIndex + 1].interiorOption
        setTimeout(() => {
          virtualTour.value!.takeSnapshot()
        }, 7500)
      } else {
        for (const { file, rotation, camera, interiorOption } of snapshotSequence.value) {
          const formData = new FormData()
          formData.set('thumbnail', file!)
          formData.set('rotation', rotation!)
          await updateUnitThumbnail(interiorOption, camera!, formData)
        }
        snapshotSequence.value = null
      }
    }
  }
}

const goToCamera = (cameraId: string) => {
  virtualTour.value!.goToCamera(cameraId)
}

const onViewUpdate = (interiorId: string, cameraId: string) => {
  currentInteriorId.value = interiorId
  currentCameraId.value = cameraId
}

const deleteThumbnail = (interiorId: string, cameraId: string) => {
  deleteUnitThumbnail(interiorId, cameraId)
}

const reorderThumbnails = async (cameraIds: Array<string>) => {
  for (const interiorOption of validInteriorOptions.value) {
    await reorderUnitThumbnails(interiorOption.guid, cameraIds)
  }
}

const unitId = useRouteId('unitId')
readUnit(unitId).then(async () => {
  listUnitUsers()
  listUnitVersions()
  const is = interiorOptions.data || (await listInteriorOptions()).data
  if (isAdmin) {
    apartmentData.data || (await listApartmentData(is![0].guid))
    thumbnailData.data || (await listThumbnailData(is![0].guid))
  }
})
</script>
