<template>
  <vis-sheet>
    <v-row>
      <v-col cols="12">
        <vis-sheet
          ><h4>
            {{ $t('webhookIntegrations') }}
            <v-tooltip location="left">
              <template #activator="{ props }">
                <vis-add-icon-btn
                  style="float: right"
                  v-bind="props"
                  @click="() => router.push({ name: 'createWebhookIntegration' })"
                />
              </template>
              {{ $t('create') }}
            </v-tooltip>
          </h4>
          <vis-loader :loading="integrations.isFetching">
            <data-table
              :columns="columns"
              :items="integrations.data! || []"
              @select="onSelect"
              @delete="onDelete"
            >
              <template #actions.prepend="{ item }">
                <v-tooltip location="left">
                  <template #activator="{ props }">
                    <vis-icon-btn icon="mdi-sync" v-bind="props" @click.stop="sync(item)" />
                  </template>
                  {{ $t('sync') }} </v-tooltip
                >&nbsp;
              </template>
            </data-table>
          </vis-loader>
        </vis-sheet>
      </v-col>
      <vis-loader style="align-items: flex-start; width: 100%" :loading="integrations.isFetching">
        <router-view />
      </vis-loader>
    </v-row>
  </vis-sheet>
  <v-dialog v-model="syncData.init">
    <v-card class="w-100 pa-8" style="max-height: calc(100vh - 50px); overflow: auto">
      <template v-if="!syncData.from">
        {{ t('loadingUnitData') }}
      </template>
      <template v-else-if="!syncData.to">
        {{ t('loadingSyncData') }}
      </template>
      <template v-else>
        <vis-btn class="mb-4" @click="commitSync">{{ t('commitSync') }}</vis-btn>
        <pre style="font-size: 80%" v-html="diff" />
      </template>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { computed, reactive, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import * as Diff from 'diff'
import DataTable from '@/components/dataTable/DataTable.vue'
import useIntegrationTableDefinitions from '@/composables/tableDefinitions/useIntegrationTableDefinitions'
import useIntegrationStore from '@/stores/integration'
import useApi from '@/composables/useApi'
import useProjectStore from '@/stores/project'
import useUnitStore from '@/stores/unit'
import type Integration from '@/types/integration'
import type Unit from '@/types/unit'

const { t } = useI18n()
const router = useRouter()
const { integrations, listIntegrations, deleteIntegration } = useIntegrationStore()
integrations.data || listIntegrations()
const columns = useIntegrationTableDefinitions()
const { project } = useProjectStore()
const { $reset } = useUnitStore()
const api = useApi()

const syncData = reactive<{
  init: boolean
  loading: boolean
  integration?: Integration
  from?: Array<Unit>
  to?: Array<Unit>
}>({
  init: false,
  loading: false
})

watch(
  () => syncData.init,
  () => {
    if (syncData.init === false) {
      syncData.loading = false
      syncData.integration = undefined
      syncData.from = undefined
      syncData.to = undefined
    }
  }
)

const diff = computed(() => {
  const diff = Diff.diffJson(syncData.from!, syncData.to!)
  let html = ''
  diff.forEach((part) => {
    let node = part.value
    if (part.added) {
      node = `<span class="text-success">${node}</span>`
    } else if (part.removed) {
      node = `<span class="text-error">${node}</span>`
    }
    html += node
  })
  return html
})

async function onSelect(data: Partial<Integration>) {
  router.push({
    name: 'updateWebhookIntegration',
    params: { integrationId: data.id }
  })
}

async function onDelete(data: Partial<Integration>) {
  if (confirm(t('areYouSureYouWantToDeleteTheIntegration'))) {
    router.push({ name: 'webhookIntegrations' })
    await deleteIntegration(data.id)
    await listIntegrations()
  }
}

async function sync(integration: Integration) {
  if (confirm('areYouSureYouWantToInitiateASync')) {
    syncData.init = true
    syncData.integration = integration
    syncData.loading = true
    const units = await (
      await api(`/project/${project.data!.id}/unit`, { method: 'GET' }).execute(true)
    ).json()
    const sync = await (
      await api(`/project/${project.data!.id}/integration/${integration.id}?commit=false`, {
        method: 'POST'
      }).execute(true)
    ).json()
    syncData.loading = false
    syncData.from = [...units].map((unit) => {
      delete unit.rowData.updatedAt
      return unit.rowData
    })
    syncData.to = [...sync].map((unit) => {
      delete unit.rowData.updatedAt
      return unit.rowData
    })
  }
}

async function commitSync() {
  await api(`/project/${project.data!.id}/integration/${syncData.integration!.id}`, {
    method: 'POST'
  }).execute(true)
  $reset()
  syncData.init = false
}
</script>
