<template>
  <vis-form ref="formRef" @submit.prevent="submit">
    <v-row>
      <v-col sm="6" cols="12">
        <vis-text-field
          name="name"
          :placeholder="$t('name')"
          :label="$t('name')"
          v-model="project.name"
        />
      </v-col>
      <v-col sm="6" cols="12">
        <vis-text-field
          name="slug"
          :placeholder="$t('slug')"
          :label="$t('slug')"
          v-model="project.slug"
        />
      </v-col>
      <v-col sm="6" cols="12">
        <vis-text-field
          name="returnUrl"
          :placeholder="$t('returnUrl')"
          :label="$t('returnUrl')"
          v-model="project.returnUrl"
        />
      </v-col>
      <v-col sm="6" cols="12">
        <vis-text-field
          name="timezone"
          :placeholder="$t('timezone')"
          :label="$t('timezone')"
          v-model="project.timezone"
        />
      </v-col>
      <v-col sm="6" cols="12">
        <vis-text-field
          name="latitude"
          :placeholder="$t('latitude')"
          :label="$t('latitude')"
          v-model="project.latitude"
        />
      </v-col>
      <v-col sm="6" cols="12">
        <vis-text-field
          name="longitude"
          :placeholder="$t('longitude')"
          :label="$t('longitude')"
          v-model="project.longitude"
        />
      </v-col>
      <v-col sm="6" cols="12">
        <vis-text-field
          name="sizeUnit"
          :placeholder="$t('sizeUnit')"
          :label="$t('sizeUnit')"
          v-model="project.sizeUnit"
        />
      </v-col>
      <v-col sm="6" cols="12">
        <vis-text-field
          name="currency"
          :placeholder="$t('currency')"
          :label="$t('currency')"
          v-model="project.currency"
        />
      </v-col>

      <v-col sm="6" cols="12">
        <vis-select
          name="state"
          :placeholder="$t('state')"
          :label="$t('state')"
          :items="['inactive', 'draft', 'published']"
          v-model="project.state"
        />
      </v-col>

      <v-col sm="6" cols="12">
        <vis-file-input
          @change="emit('setLogo', $event.target?.files?.item(0))"
          :disabled="!project.id"
          :label="$t('logo')"
          :placeholder="$t('logo')"
          accept="image/png, image/jpeg, image/bmp"
        />
        <div class="current-logo" v-if="project.logo">
          <br />
          <vis-remove-icon-btn v-bind="props" @click.stop="confirmDeleteLogo" />&nbsp;{{
            $t('currentLogo')
          }}
          <div class="pt-2">
            <img :src="`${project.assetRoot}/${project.logo}`" />
          </div>
        </div>
      </v-col>

      <v-col cols="12">
        <span>{{ $t('themeColor') }}:</span>
        <v-color-picker
          class="mt-2"
          v-model="project.themeColor"
          mode="hex"
          hide-canvas
          width="100%"
          :modes="['rgb', 'hex', 'hsl']"
        />
      </v-col>

      <v-col sm="6" cols="12">
        <vis-text-field
          name="dataSources"
          :placeholder="$t('dataSources')"
          :label="$t('dataSources')"
          v-model="project.dataSources"
        />
      </v-col>

      <v-col sm="6" cols="12">
        <vis-switch
          name="collectEndUserFeeback"
          :label="$t('collectEndUserFeedback')"
          v-model="project.collectEndUserFeeback"
        />
      </v-col>

      <v-divider class="mt-10 mb-5"></v-divider>

      <v-col cols="12">
        <span class="font-weight-bold">Player settings</span>
      </v-col>

      <v-col cols="12" class="mt-5">
        <span class="font-weight-bold text-caption">Building Chooser Settings</span>
      </v-col>
      <v-col sm="6" cols="12">
        <vis-select
          :items="buildingPickerViewModes"
          name="buildingPickerViewMode"
          :placeholder="$t('buildingPickerViewMode')"
          :label="$t('buildingPickerViewMode')"
          v-model="project.buildingPickerViewMode"
        />
      </v-col>

      <v-col sm="6" cols="12">
        <vis-text-field
          name="buildingChooserTimeOfDay"
          :placeholder="$t('buildingChooserTimeOfDay')"
          :label="$t('buildingChooserTimeOfDay')"
          v-model="project.buildingChooserTimeOfDay"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <vis-select
          :items="cardBehaviour"
          name="cardBehaviour"
          :placeholder="$t('cardBehaviour')"
          :label="$t('buildingChooserCardBehaviour')"
          v-model="project.cardBehaviour"
      /></v-col>
    </v-row>
    <vis-sheet class="mt-3" v-if="project.cardBehaviour === 'custom'">
      <VisPositionInput
        :title="$t('buildingChooserCardPosition')"
        :sub-Title="$t('clickToSetPosition')"
        :initial-position="
          project.cardCustomPosition ? project.cardCustomPosition : { top: 0, left: 0 }
        "
        @update="(updatedCardCustomPosition) => updateCardCustomPosition(updatedCardCustomPosition)"
      />
    </vis-sheet>

    <v-row>
      <v-col cols="12" class="mt-5">
        <span class="font-weight-bold text-caption">Virtual Tour Settings</span>
      </v-col>

      <v-col sm="6" cols="12">
        <vis-select
          :items="unitViewModes"
          name="unitViewMode"
          :placeholder="$t('unitViewMode')"
          :label="$t('unitViewMode')"
          v-model="project.unitViewMode"
        />
      </v-col>

      <v-col sm="6" cols="12">
        <vis-select
          :items="[1, 2]"
          name="playerVersion"
          :placeholder="$t('playerVersion')"
          :label="$t('playerVersion')"
          v-model="project.playerVersion"
        />
      </v-col>

      <v-col sm="6" cols="12">
        <vis-switch name="showCompass" :label="$t('showCompass')" v-model="project.showCompass" />
      </v-col>
      <v-col sm="6" cols="12">
        <vis-switch name="showMinimap" :label="$t('showMinimap')" v-model="project.showMinimap" />
      </v-col>
      <v-col sm="6" cols="12">
        <vis-switch
          name="enableAutoplay"
          :label="$t('enableAutoplay')"
          v-model="project.enableAutoplay"
        />
      </v-col>
      <v-col sm="6" cols="12">
        <vis-switch name="showAlert" :label="$t('showAlert')" v-model="project.showAlert" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-label>{{ $t('alertHtml') }}</v-label>
        <vis-wysiwyg v-model="project.alertHtml" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="text-right">
        <vis-submit-btn type="submit">{{ $t('submit') }}</vis-submit-btn>
      </v-col>
    </v-row>
  </vis-form>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import useFormPartials from '@/composables/useFormPartials'
import VisPositionInput from '@/components/inputs/VisPositionInput.vue'
import VisWysiwyg from '@/components/inputs/VisWysiwyg.vue'
import type Project from '@/types/project'
import {
  ProjectBuildingPickerViewMode,
  ProjectUnitViewMode,
  ProjectCardBehaviour
} from '@/types/project'

const props = defineProps<{ project?: Project }>()
const emit = defineEmits<{
  submit: [project: Partial<Project>]
  setLogo: [file: File]
  unsetLogo: [project: Partial<Project>]
}>()

const project = reactive<Partial<Project>>(props.project || {})
if (!project.themeColor) {
  project.themeColor = '#FFFFFF'
}

const { t } = useI18n()

const { formRef, submit } = useFormPartials(
  project,
  (partial: Partial<Project>) => emit('submit', partial),
  'themeColor',
  'cardCustomPosition',
  'alertHtml'
)

const buildingPickerViewModes = Object.entries(ProjectBuildingPickerViewMode).map(
  ([title, value]) => ({
    title: t(title),
    value
  })
)

const unitViewModes = Object.entries(ProjectUnitViewMode).map(([, value]) => ({
  title: t(value),
  value
}))

const cardBehaviour = Object.entries(ProjectCardBehaviour).map(([, value]) => ({
  title: t(value),
  value
}))

const updateCardCustomPosition = (cardCustomPosition: object) => {
  project.cardCustomPosition = cardCustomPosition
}

function confirmDeleteLogo() {
  if (confirm('unsetProjectLogo')) {
    emit('unsetLogo', project)
  }
}
</script>

<style scoped>
.current-logo img {
  max-width: 100%;
}
</style>
