<template>
  <vis-form ref="formRef" @submit.prevent="submit">
    <v-row>
      <v-col sm="4" cols="12">
        <vis-select
          name="buildingId"
          :placeholder="$t('building')"
          :label="$t('building')"
          v-model="unit.buildingId"
          :items="buildings.map(({ id, name }) => ({ value: id, title: name }))"
        />
      </v-col>
      <v-col sm="4" cols="12">
        <vis-text-field
          name="unitId"
          :placeholder="$t('unitId')"
          :label="$t('unitId')"
          v-model="unit.unitId"
        />
      </v-col>
      <v-col sm="4" cols="12">
        <vis-select
          name="status"
          :placeholder="$t('status')"
          :label="$t('status')"
          v-model="unit.status"
          :items="statuses.map(({ id, label }) => ({ value: id, title: translator(label) }))"
        />
      </v-col>
      <v-col sm="4" cols="12">
        <vis-text-field
          name="title"
          :placeholder="$t('title')"
          :label="$t('title')"
          v-model="unit.title"
        />
      </v-col>
      <v-col sm="4" cols="12">
        <vis-text-field
          name="size"
          :placeholder="$t('size')"
          :label="$t('size')"
          v-model="unit.size"
        />
      </v-col>
      <v-col sm="4" cols="12">
        <vis-text-field
          name="price"
          :placeholder="$t('price')"
          :label="$t('price')"
          v-model="unit.price"
        />
      </v-col>
      <v-col sm="4" cols="12">
        <vis-text-field
          name="floor"
          :placeholder="$t('floor')"
          :label="$t('floor')"
          v-model="unit.floor"
        />
      </v-col>
      <v-col sm="4" cols="12">
        <vis-text-field
          name="rooms"
          :placeholder="$t('rooms')"
          :label="$t('rooms')"
          v-model="unit.rooms"
        />
      </v-col>
      <v-col sm="4" cols="12">
        <vis-text-field
          name="pRoomSize"
          :placeholder="$t('pRoomSize')"
          :label="$t('pRoomSize')"
          v-model="unit.pRoomSize"
        />
      </v-col>
      <v-col sm="4" cols="12">
        <vis-text-field
          name="apartmentType"
          :placeholder="$t('apartmentType')"
          :label="$t('apartmentType')"
          v-model="unit.apartmentType"
        />
      </v-col>
      <v-col sm="4" cols="12">
        <vis-text-field
          name="buyUrl"
          :placeholder="$t('buyUrl')"
          :label="$t('buyUrl')"
          v-model="unit.buyUrl"
        />
      </v-col>
      <v-divider></v-divider>
      <v-col v-for="field of editableFields" :key="field.key" sm="4" cols="12">
        <template v-if="['number', 'currency', 'area'].includes(field.type)">
          <vis-text-field
            v-model.number="unit.customColumnValues![field.key]"
            :name="`customColumnValues[${field.key}]`"
            :placeholder="translator(field.label)"
            :label="translator(field.label)"
          />
        </template>
        <template v-else-if="['string', 'html'].includes(field.type)">
          <vis-localized-input
            :input="unit.customColumnValues![field.key]"
            #default="{ input, locale }"
          >
            <vis-text-field
              v-model="input[locale]"
              :name="`customColumnValues[${field.key}][${locale}]`"
              :placeholder="translator(field.label)"
              :label="translator(field.label)"
            />
          </vis-localized-input>
        </template>
        <template v-else-if="['url'].includes(field.type)">
          <vis-localized-input
            :input="unit.customColumnValues![field.key]"
            #default="{ input, locale }"
          >
            <vis-text-field
              v-model="input[locale]"
              :name="`customColumnValues[${field.key}][${locale}]`"
              :placeholder="translator(field.label)"
              :label="translator(field.label)"
            />
          </vis-localized-input>
        </template>
        <template v-else-if="['status'].includes(field.type)">
          <vis-text-field
            v-model="unit.customColumnValues![field.key]"
            :name="`customColumnValues[${field.key}]`"
            :placeholder="translator(field.label)"
            :label="translator(field.label)"
            :items="statuses.map(({ id, label }) => ({ value: id, title: translator(label) }))"
          />
        </template>
        <template v-else-if="['date'].includes(field.type)">
          <vis-text-field
            v-model="unit.customColumnValues![field.key]"
            :name="`customColumnValues[${field.key}]`"
            :placeholder="translator(field.label)"
            :label="translator(field.label)"
          />
        </template>
        <template v-else-if="['id'].includes(field.type)">
          <vis-text-field
            v-model="unit.customColumnValues![field.key]"
            :name="`customColumnValues[${field.key}]`"
            :placeholder="translator(field.label)"
            :label="translator(field.label)"
          />
        </template>
        <template v-else-if="['boolean'].includes(field.type)">
          <vis-switch
            v-model="unit.customColumnValues![field.key]"
            :name="`customColumnValues[${field.key}]`"
            :placeholder="translator(field.label)"
            :label="translator(field.label)"
          />
        </template>
      </v-col>
      <v-divider></v-divider>
      <v-col cols="12">
        <vis-localized-input :input="unit.description!" #default="{ input, locale }">
          <v-label>{{ $t('description') }}</v-label>
          <vis-wysiwyg name="description" v-model="input[locale]" />
        </vis-localized-input>
      </v-col>

      <v-col cols="12">
        <ImageSelectorSingle
          v-model="unit.featuredImage"
          :label="$t('featuredImage')"
          :category="ProjectImagesCategory.UnitFeaturedImage"
        />
      </v-col>

      <v-col cols="12">
        <ImageSelector
          v-model="unit.floorplans"
          :label="$t('floorplanImages')"
          :category="ProjectImagesCategory.FloorPlans"
          :multiple="true"
        />
      </v-col>

      <v-col cols="12" v-if="project.unitViewMode === ProjectUnitViewMode.ManualSlideShow">
        <ImageSelector v-model="unit.images" :label="$t('slideShowImages')" :multiple="true" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-right">
        <vis-submit-btn type="submit">{{ $t('submit') }}</vis-submit-btn>
      </v-col>
    </v-row>
  </vis-form>
</template>

<script setup lang="ts">
import useFormPartials from '@/composables/useFormPartials'
import type Unit from '@/types/unit'
import { reactive, ref } from 'vue'
import VisLocalizedInput from '../inputs/VisLocalizedInput.vue'
import type Field from '@/types/field'
import useI18nTranslator from '@/composables/useI18nTranslator'
import type Status from '@/types/status'
import type Building from '@/types/building'
import { ProjectImagesCategory } from '@/types/image'
import { ProjectUnitViewMode } from '@/types/project'
import type Project from '@/types/project'
import ImageSelector from './../media/ImageSelector.vue'
import ImageSelectorSingle from '../media/ImageSelectorSingle.vue'
import VisWysiwyg from '@/components/inputs/VisWysiwyg.vue'

const props = defineProps<{
  project: Project
  unit?: Unit
  buildings: Array<Building>
  fields: Array<Field>
  statuses: Array<Status>
}>()

const emit = defineEmits<{
  submit: [unit: Partial<Unit>, file?: File]
}>()

const translator = useI18nTranslator()

const unit = reactive<Partial<Unit>>(props.unit || {})
if (!unit.customColumnValues) {
  unit.customColumnValues = {}
}

for (const field of props.fields) {
  if (['html', 'url', 'string'].includes(field.type) && !unit.customColumnValues[field.key]) {
    unit.customColumnValues[field.key] = {}
  }
}

if (!unit.description) {
  unit.description = {}
}

if (!unit.images) {
  unit.images = []
}
if (!unit.floorplans) {
  unit.floorplans = []
}

const editableFields = props.fields.filter((f) => !f.isSystem && !f.isReadOnly)

const file = ref()

const { formRef, submit } = useFormPartials(
  unit,
  () => {
    emit('submit', unit, file.value)
  },
  'customColumnValues'
)
</script>
