<template>
  <template v-if="route.name === 'units'">
    <vis-loader :loading="units.isFetching || buildings.isFetching || statuses.isFetching">
      <v-row>
        <v-col sm="3" cols="12">
          <data-table-filter
            :columns="columns"
            :items="items"
            :statuses="statuses.data!"
            @update="filtered = $event"
            search-on-bottom
          />
        </v-col>
        <v-col sm="9" cols="12">
          <data-table
            :columns="columns"
            :items="filtered"
            :project="project.data!"
            :statuses="statuses.data!"
            v-on="{
              add: check(KeycloakScope.UNIT_CREATE) ? onAdd : undefined,
              select: check(KeycloakScope.UNIT_UPDATE) ? onSelect : undefined,
              delete: check(KeycloakScope.UNIT_DELETE) ? onDelete : undefined
            }"
          >
            <template #actions.append="itemProps">
              <v-tooltip location="left">
                <template #activator="{ props }">
                  <vis-view-icon-btn
                    :link="true"
                    @click.stop="view(itemProps.item)"
                    class="ml-2"
                    v-bind="props"
                  />
                </template>
                <span>{{ $t('viewUnit') }}</span>
              </v-tooltip>

              <v-tooltip location="left">
                <template #activator="{ props }">
                  <vis-icon-btn
                    icon="mdi-application-brackets"
                    :link="true"
                    @click.stop="viewEmbed(itemProps.item)"
                    class="ml-2"
                    v-bind="props"
                  />
                </template>
                <span>{{ $t('viewUnit') }} (embed)</span>
              </v-tooltip>
            </template>

            <template #top>
              <span>
                <v-dialog max-width="500">
                  <template v-slot:activator="{ props: activatorProps }">
                    <v-tooltip location="left">
                      <template #activator="{ props: tooltipProps }">
                        <vis-icon-btn
                          icon="mdi-application-brackets"
                          v-bind="{ ...activatorProps, ...tooltipProps }"
                          class="mr-2"
                        >
                        </vis-icon-btn>
                      </template>
                      <span>Export embed urls</span>
                    </v-tooltip>
                  </template>

                  <template v-slot:default="{ isActive }">
                    <v-card title="Export Embed urls">
                      <v-card-text>
                        <div class="d-flex justify-center align-top">
                          <v-select
                            v-model="embedLanguage"
                            :label="`${$t('select')} ${$t('language')}`"
                            :items="
                              locales.map((locale) => ({
                                value: locale,
                                text: $t(`lang${locale.toUpperCase()}`)
                              }))
                            "
                            variant="outlined"
                            item-value="value"
                            item-title="text"
                            density="compact"
                          ></v-select>
                          <v-btn
                            color="primary"
                            prepend-icon="mdi-download"
                            text="download"
                            class="ml-2 text-capitalize"
                            @click="exportCSV(embedLanguage)"
                          />
                        </div>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn text="Close" @click="isActive.value = false"></v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
              </span>
            </template>
          </data-table>
        </v-col>
      </v-row>
    </vis-loader>
  </template>
  <template v-else>
    <router-view />
  </template>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import useUnitStore from '@/stores/unit'
import useProjectStore from '@/stores/project'
import useStatusStore from '@/stores/status'
import useBuildingStore from '@/stores/building'
import DataTable from '@/components/dataTable/DataTable.vue'
import DataTableFilter from '@/components/dataTable/DataTableFilter.vue'
import useUnitTableDefinitions from '@/composables/tableDefinitions/useUnitTableDefinitions'
import useWatchFetchResult from '@/composables/useWatchFetchResult'
import type Unit from '@/types/unit'
import { useI18n } from 'vue-i18n'
import useScopeCheck from '@/composables/useScopeCheck'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'
import CsvExporter from '@/utils/CsvExporter'
import { locales } from '@/configs/i18n'

const check = useScopeCheck()
const route = useRoute()
const router = useRouter()
const { t } = useI18n()

const { project } = useProjectStore()
const { statuses, listStatuses } = useStatusStore()

statuses.data || listStatuses()
const { buildings, listBuildings } = useBuildingStore()
buildings.data || listBuildings()

const { units, listUnits, deleteUnit } = useUnitStore()
units.data || listUnits()

const items = useWatchFetchResult(units, [])
const columns = computed(() => useUnitTableDefinitions(buildings.data!))
const filtered = ref<Array<Unit>>([])

const onAdd = () => router.push({ name: 'createUnit' })

const getBuildingSlug = (buildingId: number) => {
  return buildings.data?.find((b) => b.id === buildingId)?.slug
}

const onSelect = (unit: Unit) =>
  router.push({
    name: 'updateUnit',
    params: {
      unitId: unit.id
    }
  })

const onDelete = async (unit: Unit) => {
  if (confirm(t('areYouSureYouWantToDeleteTheUnit', [unit.id]))) {
    await deleteUnit(unit.id)
    listUnits()
  }
}

const getUnitFrontendUrl = (unit: Unit) => {
  const unitId = unit.unitId
  const buildingId = unit.buildingId
  const buildingSlug = getBuildingSlug(buildingId)
  const projectSlug = project.data!.slug

  const frontEndRoot = import.meta.env.VITE_FRONTEND_URL

  return `${frontEndRoot}/${projectSlug}/${buildingSlug}/${unitId}`
}

const view = (unit: Unit) => {
  const url = getUnitFrontendUrl(unit)
  window.open(url)
}

const getUnitEmbedUrl = (unit: Unit) => {
  const unitId = unit.unitId
  const buildingId = unit.buildingId
  const buildingSlug = getBuildingSlug(buildingId)
  const projectSlug = project.data!.slug
  const playerRoot = import.meta.env.VITE_PLAYER_URL
  return `${playerRoot}&project=${projectSlug}&building=${buildingSlug}&unit=${unitId}`
}

const viewEmbed = (unit: Unit) => {
  const url = getUnitEmbedUrl(unit)
  window.open(url)
}

const exportCSV = (language: string) => {
  let rows: any[] = []

  for (const unit of filtered.value) {
    rows.push([unit.title, `${getUnitEmbedUrl(unit)}&language=${language}`])
  }

  const exporter = new CsvExporter(
    rows,
    ['unit', 'Embed Url'],
    `${project.data!.slug}-units-embed-urls`
  )
  exporter.exportCsvFile()
}

const embedLanguage = ref('nb')
</script>
