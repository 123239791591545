<template>
  <v-row class="w-100">
    <v-col cols="12">
      <vis-sheet>
        <v-table>
          <thead>
            <tr>
              <th>{{ $t('save') }}?</th>
              <th>{{ $t('changes') }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(d, index) of data" :key="index">
              <tr v-if="hasDiff(d)">
                <td>
                  <vis-switch v-model="selected" :value="d.unitId" />
                </td>
                <td class="pa-2">
                  <pre v-html="getDiff(d)" />
                </td>
              </tr>
            </template>
          </tbody>
        </v-table>
      </vis-sheet>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import * as Diff from 'diff'
import { inject, onMounted, type Ref } from 'vue'
import { ref } from 'vue'

const { data, previous } = defineProps<{
  previous: Array<{ [key: string]: any }>
  data: Array<{ [key: string]: any }>
}>()

const emit = defineEmits<{
  commit: [Array<string>]
  back: []
}>()

const selected = ref<Array<string>>([])

const backLabel = inject<Ref<string>>('backLabel')
const backHandler = inject<Ref<undefined | (() => void)>>('backHandler')
backLabel!.value = 'binding'
backHandler!.value = () => {
  emit('back')
}

const nextLabel = inject<Ref<string>>('nextLabel')
const nextHandler = inject<Ref<undefined | (() => void)>>('nextHandler')
nextLabel!.value = 'commit'
nextHandler!.value = undefined

const hasDiff = (data: { [key: string]: any }) => {
  const d = { ...previous.find((d) => d.id === data.id) }
  if (d) {
    const dd = { ...data }
    delete d.updatedAt
    delete dd.updatedAt
    return Diff.diffJson(d, dd).length > 1
  } else {
    return true
  }
}

const getDiff = (data: { [key: string]: any }) => {
  const d = { ...previous.find((d) => d.id === data.id) }
  if (d) {
    const dd = { ...data }
    delete d.updatedAt
    delete dd.updatedAt
    let html = ''
    const diff = Diff.diffJson(d, dd)
    diff.forEach((part) => {
      let node = part.value
      if (part.added) {
        node = `<span class="text-success">${node}</span>`
      } else if (part.removed) {
        node = `<span class="text-error">${node}</span>`
      }
      html += node
    })
    return html
  } else {
    return `<span class="text-success">${JSON.stringify(data, null, 2)}</span>`
  }
}

nextHandler!.value = () => {
  emit('commit', selected.value)
}

onMounted(() => {
  selected.value = previous.map((p) => p.unitId)
})
</script>

<style scoped>
td:last-child {
  font-size: 80%;
}
</style>
