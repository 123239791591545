import { ref, watch } from 'vue'
import { createI18n } from 'vue-i18n'

const i18n = createI18n({
  legacy: false,
  locale: localStorage.getItem('LOCALE') || 'en',
  fallbackLocale: 'en'
})

const locales = ref<Array<string>>([])
const messages = ref<{ [key: string]: { [key: string]: string } }>()
let vuetifyMessages: { [key: symbol]: { [key: string]: string } }

watch(
  i18n.global.locale,
  async () => {
    const locale = i18n.global.locale.value
    localStorage.setItem('LOCALE', locale)
    if (!messages.value) {
      const response = await fetch(`https://l10n.visuado.com/all.json`)
      messages.value = await response.json()
      locales.value = Object.keys(messages.value!)
    }
    if (!vuetifyMessages) {
      vuetifyMessages = await import('vuetify/locale')
    }
    i18n.global.setLocaleMessage(locale, {
      ...messages.value![locale],
      $vuetify: {
        ...(locale in vuetifyMessages
          ? (vuetifyMessages as any)[locale]
          : vuetifyMessages['en' as any])
      }
    })
  },
  {
    immediate: true
  }
)

export default i18n
export { messages, locales }
