<template>
  <vis-data-table :headers="columns" :items="unitRows">
    <template #top>
      <div class="d-flex justify-end pa-4 pb-1">
        <v-tooltip text="Tooltip" location="left">
          <template #activator="{ props }">
            <vis-icon-btn icon="mdi-download-box" v-bind="props" @click="download" />
          </template>
          {{ $t('downloadCsv') }}
        </v-tooltip>
      </div>
    </template>
    <template #bottom />
    <template
      v-for="{ type, key, customRenderer } of tableColumns"
      :key="key"
      #[key]="{ value, item }"
    >
      <template v-if="!getFieldByKey(key).isReadOnly">
        <v-tooltip location="top">
          <template #activator="{ props }">
            <div v-bind="props" @click="edit(item, key)">
              <data-table-cell-renderer
                v-if="value"
                :type="type"
                :project="project"
                :statuses="statuses"
                :value="value"
                :customRenderer="customRenderer"
              />
              <template v-else>&nbsp;</template>
            </div>
          </template>
          click to edit
        </v-tooltip>
      </template>
      <template v-else>
        <data-table-cell-renderer
          :type="type"
          :project="project"
          :statuses="statuses"
          :value="value"
          :customRenderer="customRenderer"
        />
      </template>
    </template>
  </vis-data-table>
  <v-overlay class="justify-center align-center" :modelValue="!!editField" @click:outside="reset()">
    <vis-sheet style="min-width: 50vw">
      <vis-form v-if="editField" @submit="save()">
        <template v-if="editField.key === 'building'">
          <vis-select
            :items="buildings.map(({ id: value, name: title }) => ({ title, value }))"
            :placeholder="translator(editField.label)"
            :label="translator(editField.label)"
            v-model="editValue"
          />
        </template>
        <template v-else-if="editField.type === 'string'">
          <vis-localized-input :input="editValue" #default="{ input, locale }">
            <vis-text-field
              :placeholder="translator(editField.label)"
              :label="translator(editField.label)"
              v-model="input[locale]"
            />
          </vis-localized-input>
        </template>
        <template v-else-if="editField.type === 'status'">
          <vis-select
            :items="statuses.map(({ id: value, label }) => ({ title: translator(label), value }))"
            :placeholder="translator(editField.label)"
            :label="translator(editField.label)"
            v-model="editValue"
          />
        </template>
        <template v-else-if="editField.type === 'date'">
          <label>{{ translator(editField.label) }}</label>
          <vue-date-picker
            :format="(date: Date) => date.toLocaleString()"
            utc
            v-model="editValue"
          />
        </template>
        <template v-else-if="editField.type === 'boolean'">
          <vis-switch
            :placeholder="translator(editField.label)"
            :label="translator(editField.label)"
            v-model="editValue"
          />
        </template>
        <template v-else-if="editField.type === 'html'">
          <vis-text-field
            :placeholder="translator(editField.label)"
            :label="translator(editField.label)"
            v-model="editValue"
          />
        </template>
        <template v-else-if="editField.type === 'id'">
          <vis-text-field
            :placeholder="translator(editField.label)"
            :label="translator(editField.label)"
            v-model="editValue"
          />
        </template>
        <template v-else-if="editField.type === 'url'">
          <vis-localized-input :input="editValue" #default="{ input, locale }">
            <vis-text-field
              :placeholder="translator(editField.label) + ` (${$t('name')})`"
              :label="translator(editField.label)"
              v-model="input[locale][0]"
            />
            <vis-text-field
              :placeholder="translator(editField.label) + ` (${$t('url')})`"
              :label="translator(editField.label)"
              v-model="input[locale][1]"
            />
          </vis-localized-input>
        </template>
        <template v-else-if="['number', 'area', 'currency'].includes(editField.type)">
          <vis-text-field
            type="number"
            :placeholder="translator(editField.label)"
            :label="translator(editField.label)"
            v-model="editValue"
          />
        </template>
        <vis-submit-btn class="w-100 mt-2" type="submit">{{ $t('save') }}</vis-submit-btn>
      </vis-form>
    </vis-sheet>
  </v-overlay>
</template>

<script setup lang="ts">
import { computed, h, ref } from 'vue'
import { RouterLink } from 'vue-router'
import { VTooltip } from 'vuetify/components'
import { locales } from '@/configs/i18n'
import DataTableCellRenderer from '../dataTable/DataTableCellRenderer'
import useTableColumnConverter from '@/composables/useTableColumnConverter'
import useI18nTranslator from '@/composables/useI18nTranslator'
import VisLocalizedInput from '../inputs/VisLocalizedInput.vue'
import CsvExporter from '@/utils/CsvExporter'
import type Field from '@/types/field'
import type Building from '@/types/building'
import type Project from '@/types/project'
import type Status from '@/types/status'

const translator = useI18nTranslator()

const { project, statuses, unitRows, buildings, fields } = defineProps<{
  project: Project
  buildings: Array<Building>
  fields: Array<Field>
  statuses: Array<Status>
  unitRows: Array<{ [key: string]: any }>
}>()

const orderedFields = computed(() => {
  return [...fields].sort((a, b) => a.orderInList - b.orderInList)
})

const emit = defineEmits<{
  edit: [rowData: { [key: string]: any }]
}>()

const getFieldByKey = (key: string): Field => {
  return orderedFields.value.find((f) => `item.${f.key}` === key)!
}

const columns = orderedFields.value.map<VDataTableColumn>((field) => ({
  title: translator(field.label),
  key: field.key,
  type: field.type
}))

const descriptionColumn = columns.find((c) => c.key === 'description')
descriptionColumn!.customRenderer = ({ value }: any) => {
  return value
    ? h(
        VTooltip,
        { location: 'bottom' },
        {
          default: () => [translator(value)],
          activator: ({ props }: any) => h('span', { ...props }, ['Hover to read'])
        }
      )
    : h('span', [''])
}

const idColumn = columns.find((c) => c.key === 'id')
idColumn!.customRenderer = ({ value }: any) => {
  const unit = unitRows.find((u) => u.id === value)
  return unit
    ? h(
        RouterLink,
        {
          class: 'text-primary',
          to: { name: 'updateUnit', params: { unitId: unit.id } }
        },
        () => unit.id
      )
    : h('span', '-')
}

const buildingColumn = columns.find((c) => c.key === 'building')
buildingColumn!.customRenderer = ({ value }: any) => {
  const building = buildings.find((b) => b.id === value)
  return building
    ? h(
        RouterLink,
        {
          class: 'text-primary',
          to: { name: 'updateBuilding', params: { buildingId: building.id } }
        },
        () => building.name
      )
    : h('span', '-')
}
const tableColumns = useTableColumnConverter(columns)

const reset = () => {
  editField.value = undefined
  editRow.value = undefined
  editValue.value = undefined
}

const editField = ref<Field>()
const editRow = ref<{ [key: string]: any }>()
const editValue = ref<any>()
const edit = (item: { [key: string]: any }, key: string) => {
  editField.value = getFieldByKey(key)
  editRow.value = item
  if (editField.value.type === 'url') {
    editValue.value = item[editField.value.key].split('|')
    if (editValue.value.length === 1) {
      editValue.value.unshift('')
    }
  } else {
    editValue.value = item[editField.value.key]
  }
}

const save = () => {
  if (editField.value) {
    const data = { ...editRow.value }
    if (
      ['number', 'area', 'size', 'floor', 'pRoomSize', 'currency'].includes(editField.value.type)
    ) {
      data[editField.value!.key] = Number(editValue.value)
    } else if (editField.value.type === 'url') {
      const value = editValue.value
      for (const lang in value) {
        value[lang] = value[lang][1] ? value[lang].join('|') : value[lang][1]
      }
      data[editField.value!.key] = value
    } else {
      data[editField.value!.key] = editValue.value
    }
    emit('edit', data)
    reset()
  }
}

const download = () => {
  const rows = []
  const headers = []
  for (const field of orderedFields.value) {
    const label = translator(field.label)
    if (['string', 'url', 'html'].includes(field.type)) {
      for (const code of locales.value) {
        headers.push(`${label} (${code})`)
      }
    } else {
      headers.push(label)
    }
  }
  for (let i = 0; i < unitRows.length; i++) {
    const row = []
    for (const field of orderedFields.value) {
      if (field.key === 'building') {
        const building = buildings.find((b) => [b.slug, b.id].includes(unitRows[i][field.key]))
        row.push(building!.name)
      } else if (['string', 'url', 'html'].includes(field.type)) {
        for (const code of locales.value) {
          if (unitRows[i][field.key]) {
            row.push(unitRows[i][field.key][code])
          } else {
            row.push('')
          }
        }
      } else if (field.type === 'status') {
        const status = statuses.find((s) => s.id === unitRows[i][field.key])
        row.push(translator(status!.label))
      } else {
        row.push(unitRows[i][field.key] || '')
      }
    }
    rows.push(row)
  }
  const exporter = new CsvExporter(rows, headers, project.slug)
  exporter.exportCsvFile()
}
</script>
