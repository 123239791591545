<template>
  <div class="vis-wysiwyg">
    <quill-editor
      content-type="html"
      toolbar="full"
      :modules="modules"
      :enable="!!quill"
      @ready="onReady"
      @update:content="onContentChange"
    />
    <v-dialog v-model="openImageDialog" scrollable>
      <v-skeleton-loader class="w-100" :loading="imageStore.images.isFetching">
        <v-card class="w-100" style="max-height: calc(100vh - 50px); overflow: auto">
          <v-container class="w-100" fluid>
            <v-row>
              <v-col
                v-for="image of imageStore.images.data"
                :key="image.id"
                cols="3"
                class="pa-0 ma-0"
              >
                <div
                  class="w-100 select-image"
                  :style="{
                    backgroundImage: `url(${projectStore.project.data!.assetRoot}/images/${image.category}/${image.filename})`
                  }"
                  @click="
                    () =>
                      onSelectImage(
                        `${projectStore.project.data!.assetRoot}/images/${image.category}/${image.filename}`
                      )
                  "
                ></div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-skeleton-loader>
    </v-dialog>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { Quill, QuillEditor } from '@vueup/vue-quill'
//@ts-ignore
import BlotFormatter from 'quill-blot-formatter/dist/BlotFormatter'
import useImageStore from '@/stores/image'
import useProjectStore from '@/stores/project'

const model = defineModel<string>()

const projectStore = useProjectStore()
const imageStore = useImageStore()
imageStore.images.data || imageStore.listImages()

const quill = ref<Quill>()
const openImageDialog = ref(false)
const imagePos = ref(0)

const modules = {
  name: 'blotFormatter',
  module: BlotFormatter,
  options: {}
}

watch(
  [model, quill],
  () => {
    if (quill.value) {
      if (model.value !== quill.value.root.innerHTML) {
        quill.value.root.innerHTML = model.value || ''
      }
    }
  },
  { immediate: true }
)

function onReady(q: Quill) {
  quill.value = q
  quill.value.getModule('toolbar').handlers = {
    image: () => {
      imagePos.value = quill.value!.getSelection()?.index || 0
      openImageDialog.value = true
    }
  }
}

function onSelectImage(file: string) {
  quill.value!.insertEmbed(imagePos.value, 'image', file, 'user')
  openImageDialog.value = false
}

function onContentChange() {
  if (quill.value) {
    model.value = quill.value.root.innerHTML
  }
}
</script>

<style scoped>
.select-image {
  height: 200px;
  background-position: center center;
  background-size: cover;
  cursor: pointer;
}
.select-image:hover {
  border: 1px #000000 solid;
}

.vis-wysiwyg {
  min-height: 100px;
}
</style>
<style>
.ql-container.ql-snow {
  min-height: 100px;
}
</style>
