import type { BuildingChooserDataV5, PlayerSettings } from '@qispace/vue3-player'
import type Field from './field'

export interface CrmIntegrationData {
  firstname: string
  lastname: string
  email: string
  phone: string
  message: string
}

export interface CrmIntegrationContextProject {
  name: string
}

export interface CrmIntegrationContextBuilding {
  name: string
}

export interface CrmIntegrationContextUnit {
  title: string
}

export interface CrmIntegrationContext {
  project: CrmIntegrationContextProject
  building: CrmIntegrationContextBuilding
  unit: CrmIntegrationContextUnit
}

export interface CrmIntegrationSettingsEmail {
  enabled: boolean
  address?: Array<string>
  subject?: string
}

export interface CrmIntegrationSettingsWebhooks {
  enabled: boolean
  urls?: Array<string>
}

export interface CrmIntegrationSettingsHubspot {
  enabled: boolean
  apiKey?: string
  listIdField?: string
  projectField?: string
  buildingField?: string
  unitField?: string
}

export interface CrmIntegrationSettings {
  email?: CrmIntegrationSettingsEmail
  webhooks?: CrmIntegrationSettingsWebhooks
  hubspot?: CrmIntegrationSettingsHubspot
}

export enum ProjectState {
  Inactive = 'inactive',
  Draft = 'draft',
  Published = 'published'
}

export enum ProjectBuildingPickerViewMode {
  Cards = 'cards',
  InteractiveImageWithSun = 'interactiveImageWithSun'
}

export enum ProjectCardBehaviour {
  Default = 'default',
  Static = 'static',
  Custom = 'custom'
}

export enum ProjectUnitViewMode {
  VirtualTourAndSlideShowDefaultVirtualTour = 'virtualTourAndSlideShowDefaultVirtualTour',
  VirtualTourAndSlideShowDefaultSlideShow = 'virtualTourAndSlideShowDefaultSlideShow',
  VirtualTourOnly = 'virtualTourOnly',
  SlideShowOnly = 'slideShowOnly',
  ManualSlideShow = 'manualSlideShow'
}

export default interface Project {
  id?: number
  clientId: number
  name: string
  slug: string
  state: ProjectState
  returnUrl?: string
  showAlert: boolean
  alertHtml?: string
  alertHash?: string
  unitViewMode: ProjectUnitViewMode
  description?: string
  buildingPickerViewMode: ProjectBuildingPickerViewMode
  currency: string
  sizeUnit: string
  showCompass: boolean
  crmIntegrations?: CrmIntegrationSettings
  gtmID: string
  metaPixelID: string
  svgReady?: boolean
  cardBehaviour?: string
  cardCustomPosition?: object
  longitude?: number
  latitude?: number
  collectEndUserFeeback?: boolean
  showMinimap?: boolean
  enableAutoplay?: boolean
  buildingChooserTimeOfDay?: string
  timezone: string
  playerVersion: number
  dataSources: string
  buildingChooserCustomOverlay: string
  assetRoot: string
  dataRoot: string
  roomCategories: object
  playerSettings: PlayerSettings
  logo: string
  themeColor: string
  updatedAt: string
  createdAt: string
  hasCrmIntegrations: boolean
  internalFields: Array<Field>
  buildingChooserData: BuildingChooserDataV5
}
