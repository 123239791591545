<template>
  <v-main>
    <v-container fluid class="h-100 overflow-auto">
      <vis-sheet>
        <h4>{{ $t('addANewProject') }}</h4>
        <vis-loader :loading="project.isFetching || clients.isFetching">
          <vis-form @submit.prevent="onSubmit">
            <v-row>
              <v-col cols="12">
                <vis-select
                  :items="clients.data!.map((c) => ({ title: c.name, value: c.id })) || []"
                  v-model="clientId"
                  name="clientId"
                  :label="$t('client')"
                  :placeholder="$t('client')"
                />
              </v-col>
              <v-col cols="12">
                <vis-text-field
                  v-model="name"
                  name="name"
                  :label="$t('name')"
                  :placeholder="$t('name')"
                />
              </v-col>
              <v-col cols="12">
                <vis-text-field
                  v-model="slug"
                  name="slug"
                  :label="$t('slug')"
                  :placeholder="$t('slug')"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-right">
                <vis-submit-btn type="submit">{{ $t('submit') }}</vis-submit-btn>
              </v-col>
            </v-row>
          </vis-form>
        </vis-loader>
      </vis-sheet>
    </v-container>
  </v-main>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'
import useClientStore from '@/stores/client'
import useContextStore from '@/stores/context'
import useProjectStore from '@/stores/project'
import { ProjectState, ProjectUnitViewMode, ProjectBuildingPickerViewMode } from '@/types/project'
import { ref } from 'vue'
import { watchEffect, watch } from 'vue'
import slugify from '@/utils/slugify'

const router = useRouter()
const { project, createProject, listProjects } = useProjectStore()
const { selectProjectId } = useContextStore()

const { clients, listClients } = useClientStore()
clients.data || listClients()

const clientId = ref<number>()
watchEffect(() => {
  if (clients.data && clients.data.length > 0 && clientId.value === undefined) {
    clientId.value = clients.data[0].id
  }
})

const name = ref<string>()
const slug = ref<string>()
watch(name, (value, old) => {
  if (old === undefined || slugify(old) === slug.value) {
    slug.value = slugify(value || '')
  }
})

async function onSubmit() {
  await createProject({
    clientId: clientId.value,
    name: name.value,
    slug: slug.value,
    state: ProjectState.Draft,
    unitViewMode: ProjectUnitViewMode.VirtualTourAndSlideShowDefaultVirtualTour,
    buildingPickerViewMode: ProjectBuildingPickerViewMode.InteractiveImageWithSun,
    currency: 'EUR',
    sizeUnit: 'm²',
    showCompass: true,
    timezone: 'Europe/Oslo',
    playerVersion: 1
  })
  selectProjectId(project.data!.id!)
  await listProjects()
  router.push({ name: 'projectIndex' })
}
</script>
