<template>
  <div class="apartment-chooser-container">
    <player-error-boundary>
      <v-structure-chooser
        :i18n-config="i18nConfig"
        :structure-chooser-data="building.apartmentChooserData"
        :color-callback="colorCallback"
        :cdn-file-resolver="cdnFileResolver"
        :selected-id="currentUnit?.unitId"
        :render-display="
          (unitId: string) => props.units.find((u) => u.unitId === unitId)?.title || '-'
        "
        :version="version"
        @on-click="goToUnit"
      >
        <template #popup="{ hoverId }">
          <div class="pa-4 bg-white">
            {{ props.units.find((u) => u.unitId === hoverId)?.title }}
          </div>
        </template>
      </v-structure-chooser>
    </player-error-boundary>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  VStructureChooser,
  type Color,
  type HoverState,
  type I18NConfig
} from '@qispace/vue3-player'
import { useRouter } from 'vue-router'
import PlayerErrorBoundary from './PlayerErrorBoundary.vue'
import type Building from '@/types/building'
import type Unit from '@/types/unit'
import type Project from '@/types/project'
import type Status from '@/types/status'
import { messages } from '@/configs/i18n'

const props = defineProps<{
  building: Building
  project: Project
  units: Array<Unit>
  statuses: Array<Status>
  version?: string
}>()

const router = useRouter()
const i18n = useI18n()
const i18nConfig = ref<I18NConfig>({
  labels: messages.value,
  language: i18n.locale.value
})

const version = computed(() => props.version)
const units = computed(() => props.units.filter((u) => u.buildingId === props.building.id))
const currentUnit = ref<Unit>()

function hexToRgb(hex: string) {
  const regex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i
  const result = regex.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
        a: parseInt(result[4], 16)
      }
    : {
        r: 0,
        g: 0,
        b: 0,
        a: 0
      }
}

function colorCallback(unitId: string, state: HoverState) {
  let color: Color | undefined
  const unit = units.value.find((u) => u.unitId === unitId)
  if (unit) {
    const status = props.statuses.find((s) => s.id === unit.status)
    if (status) {
      if (state === 'idle') {
        if (status.persistentUnitColor) {
          color = {
            ...hexToRgb(status.idleColor),
            s: status.idleStrokeWidth
          }
        }
      } else if (state === 'hover') {
        color = {
          ...hexToRgb(status.highlightColor),
          s: status.highlightStrokeWidth
        }
      } else if (state === 'selected') {
        color = {
          ...hexToRgb(status.activeColor),
          s: status.activeStrokeWidth
        }
      }
      if (units.value.findIndex((u) => u.unitId === unit.unitId) < 0 && color) {
        color = {
          ...hexToRgb(status.inactiveColor),
          s: status.inactiveStrokeWidth
        }
      }
    } else {
      return { ...hexToRgb('#888888'), s: 1 }
    }
  }
  return color
}

function cdnFileResolver(filename: string) {
  return `${props.project.assetRoot}/buildings/${props.building.id}${filename}`
}

function goToUnit(unitId: string) {
  const unit = units.value.find((u) => u.unitId === unitId)
  if (unit) {
    if (currentUnit.value?.unitId !== unit.unitId) {
      currentUnit.value = unit
    } else {
      router.push(`/units/${unit.id}`)
    }
  }
}
</script>

<style scoped>
.apartment-chooser-container {
  max-width: 1280px;
  position: relative;
}
</style>
