<template>
  <div class="virtual-tour-container">
    <player-error-boundary>
      <v-virtual-tour
        ref="virtualTourPlayer"
        :i18n-config="i18nConfig"
        :cdn-file-resolver="cdnFileResolver"
        :unit-view-mode="project.unitViewMode"
        :apartment-id="unit.unitId"
        :valid-interior-ids="unit.playerData.filter((i: any) => i.isValid).map((i: any) => i.id)"
        :default-interior-id="selectedInteriorId"
        :player-settings="project.playerSettings"
        :default-view-mode="project.unitViewMode"
        :display-view-mode-switch="
          project.unitViewMode !== 'slideShowOnly' && project.unitViewMode !== 'virtualTourOnly'
        "
        :show-carousel="true"
        :show-time-of-day="true"
        :show-compass="true"
        :show-full-screen="true"
        :show-snapshot="true"
        :show-interior-options="true"
        :show-mini-map="true"
        :show-floor-level="true"
        :show-share="false"
        :default-waypoint-index="0"
        :mouse-sensitivity="1"
        :enable-autoplay="true"
        :version="version"
        @on-snapshot="onSnapshot"
        @on-interior-update="onInteriorUpdate"
        @on-camera-update="onCameraUpdate"
      >
      </v-virtual-tour>
    </player-error-boundary>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { VVirtualTour, type I18NConfig } from '@qispace/vue3-player'
import PlayerErrorBoundary from './PlayerErrorBoundary.vue'
import type Project from '@/types/project'
import type Unit from '@/types/unit'
import type InteriorOption from '@/types/interiorOption'
import { messages } from '@/configs/i18n'

const props = defineProps<{
  unit: Unit
  project: Project
  interiorOptions: Array<InteriorOption>
  interiorId?: string
  version?: string
}>()

const emit = defineEmits<{
  onViewUpdate: [interiorId: string, cameraId: string]
  onSnapShot: [file: File, rotation: string, interiorId: string, cameraId: string]
}>()

defineExpose({
  takeSnapshot,
  goToCamera
})

const version = computed(() => props.version)
const virtualTourPlayer = ref<InstanceType<typeof VVirtualTour>>()

const i18n = useI18n()
const i18nConfig = ref<I18NConfig>({
  labels: messages.value,
  language: i18n.locale.value
})

const validInteriorOptions = computed(() => {
  const validIds: Array<string> = props.unit.playerData
    .filter((i: any) => i.isValid)
    .map((i: InteriorOption) => i.id)
  return props.interiorOptions.filter((i) => validIds.includes(i.guid))
})

const selectedInteriorId = ref<string>(
  validInteriorOptions.value.find((i) => i.isDefault)?.guid ||
    (validInteriorOptions.value.length > 0 ? validInteriorOptions.value[0].guid : '')
)

watch(
  () => props.interiorId,
  () => {
    if (props.interiorId) {
      selectedInteriorId.value = props.interiorId
    }
  }
)

watch([selectedInteriorId], () => {
  virtualTourPlayer.value?.updateSelectedInterior(selectedInteriorId.value)
})

const cameraId = ref<string>('')

watch([selectedInteriorId, cameraId], () => {
  if (selectedInteriorId.value && cameraId.value) {
    emit('onViewUpdate', selectedInteriorId.value, cameraId.value)
  }
})

function cdnFileResolver(filename: string) {
  return `${props.project.dataRoot}${filename}`
}

async function onSnapshot(snapshot: any, [x, y, z]: [x: number, y: number, z: number]) {
  const response = await fetch(snapshot.data)
  const blob = await response.blob()
  const file = new File([blob], 'File name', { type: 'image/png' })
  emit('onSnapShot', file, `${x},${y},${z}`, selectedInteriorId.value, cameraId.value)
}

function onInteriorUpdate(id: string) {
  selectedInteriorId.value = id
}

function onCameraUpdate(id: string) {
  cameraId.value = id
}

function takeSnapshot() {
  virtualTourPlayer.value!.takeSnapshot()
}

function goToCamera(cameraId: string) {
  virtualTourPlayer.value!.setCurrentCamera(cameraId)
}
</script>

<style scoped>
.virtual-tour-container {
  width: 100%;
  height: 60vh;
  position: relative;
}
</style>
