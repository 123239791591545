<template>
  <v-row class="w-100">
    <v-col cols="4">
      <h5>{{ $t('registeredFields') }}</h5>
      <v-list>
        <v-list-item
          v-for="(field, index) of allFields"
          :key="index"
          :class="matchedIndex === index ? 'bg-primary' : undefined"
        >
          {{ field.check }}
        </v-list-item>
      </v-list>
    </v-col>
    <v-col cols="4">
      <h5>{{ $t('importedMatchedFields') }}</h5>
      <v-list>
        <draggable v-model="matched" group="fields" item-key="key" :move="moveMatched" @end="end">
          <template #item="{ element, index }">
            <v-list-item :class="matchedIndex === index ? 'bg-primary' : undefined">
              <i v-if="element.value === null">{{ $t('notBound') }}</i>
              <span v-else>{{ element.value }}</span>
            </v-list-item>
          </template>
        </draggable>
      </v-list>
    </v-col>
    <v-col cols="4">
      <h5>{{ $t('importedUnusedFields') }}</h5>
      <v-list>
        <draggable
          v-model="unmatched"
          group="fields"
          item-key="key"
          :move="moveUnmatched"
          @end="end"
        >
          <template #item="{ element, index }">
            <v-list-item :class="unmatchedIndex === index ? 'bg-primary' : undefined">
              <i v-if="element.value === null">{{ $t('notBound') }}</i>
              <span v-else>{{ element.value }}</span>
            </v-list-item>
          </template>
        </draggable>
      </v-list>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { inject, ref, onMounted, type Ref } from 'vue'
import draggable from 'vuedraggable'
import useI18nTranslator from '@/composables/useI18nTranslator'
import { locales } from '@/configs/i18n'
import type Field from '@/types/field'

const { fields, data } = defineProps<{
  data: Array<{ [key: string]: any }>
  fields: Array<Field>
}>()

const emit = defineEmits<{
  bounded: [Array<Field & { header: string; code: string | null; check: string }>]
  back: []
}>()

const translator = useI18nTranslator()

const backLabel = inject<Ref<string>>('backLabel')
const backHandler = inject<Ref<undefined | (() => void)>>('backHandler')
backLabel!.value = 'import'
backHandler!.value = () => {
  emit('back')
}

const nextLabel = inject<Ref<string>>('nextLabel')
const nextHandler = inject<Ref<undefined | (() => void)>>('nextHandler')
nextLabel!.value = 'bind'
nextHandler!.value = undefined

const allFields = ref<Array<Field & { code: string | null; check: string }>>([
  ...fields
    .filter((f) => !f.isReadOnly || f.key === 'unitId')
    .reduce<Array<Field & { code: string | null; check: string }>>((acc, f) => {
      if (['string', 'html', 'url'].includes(f.type)) {
        const ls = locales.value.map((code) => ({
          ...f,
          code,
          check: `${translator(f.label)} (${code})`
        }))
        return [...acc, ...ls]
      } else {
        return [...acc, { ...f, code: null, check: translator(f.label) }]
      }
    }, [])
])
const headers = Object.keys(data[0])

const matchedIndex = ref<number>(-1)
const matchedKey = ref<string>()
const matched = ref<Array<{ key: string; value: string | null }>>(
  allFields.value.map(() => ({ key: Math.random().toString(), value: null }))
)
const moveMatched = (move: any) => {
  if (move.relatedContext.list.find(({ key }: any) => key === move.draggedContext.element.key)) {
    return true
  } else {
    unmatchedIndex.value = move.relatedContext.index
    matchedKey.value = move.draggedContext.element.key
    unmatchedKey.value = move.relatedContext.element.key
    return false
  }
}

const unmatchedIndex = ref<number>(-1)
const unmatchedKey = ref<string>()
const unmatched = ref<Array<{ key: string; value: string | null }>>([
  ...headers.map((value) => ({ key: Math.random().toString(), value })),
  { key: Math.random().toString(), value: null }
])
const moveUnmatched = (move: any) => {
  if (move.relatedContext.list.find(({ key }: any) => key === move.draggedContext.element.key)) {
    return true
  } else {
    matchedIndex.value = move.relatedContext.index
    unmatchedKey.value = move.draggedContext.element.key
    matchedKey.value = move.relatedContext.element.key
    return false
  }
}

const end = () => {
  if (matchedKey.value && unmatchedKey.value) {
    const copyUnmatched = [...unmatched.value]
    const copyMatched = [...matched.value]
    const swapUnmatchedIndex = copyUnmatched.findIndex(({ key }) => key === unmatchedKey.value)
    const swapMatchedIndex = copyMatched.findIndex(({ key }) => key === matchedKey.value)
    const swapUnmatched = copyUnmatched[swapUnmatchedIndex]
    const swapMatched = copyMatched[swapMatchedIndex]
    copyMatched.splice(
      swapMatchedIndex,
      1,
      swapUnmatched.value === null ? { key: Math.random().toString(), value: null } : swapUnmatched
    )
    copyUnmatched.splice(
      swapUnmatchedIndex,
      swapUnmatched.value === null ? 0 : 1,
      ...(swapMatched.value !== null ? [swapMatched as any] : [])
    )
    matched.value = copyMatched
    unmatched.value = copyUnmatched
  }
  matchedIndex.value = -1
  unmatchedIndex.value = -1
  matchedKey.value = undefined
  unmatchedKey.value = undefined
}

onMounted(() => {
  const guesses: Array<{ field: Field; candidates: Array<string> }> = []
  for (const field of allFields.value) {
    const candidates = []
    candidates.push(field.key.toLowerCase())
    for (const lang in field.label) {
      candidates.push(field.label[lang].toLowerCase())
      for (const code of locales.value) {
        candidates.push(`${field.label[lang]} (${code})`.toLowerCase())
      }
    }
    guesses.push({ field, candidates })
  }

  let triggered = true
  while (triggered) {
    triggered = false
    for (let i = 0; i < guesses.length; i++) {
      const { candidates } = guesses[i]
      for (let j = 0; j < unmatched.value.length; j++) {
        const unmatch = unmatched.value[j]
        if (unmatch.value) {
          if (matched.value[i].value === null && candidates.includes(unmatch.value.toLowerCase())) {
            const copyUnmatched = [...unmatched.value]
            const copyMatched = [...matched.value]
            const copyUnmatch = copyUnmatched[j]
            copyUnmatched.splice(j, 1)
            copyMatched.splice(i, 1, copyUnmatch)
            matched.value = copyMatched
            unmatched.value = copyUnmatched
            triggered = true
            break
          }
        }
      }
      if (triggered) {
        break
      }
    }
  }
})

nextHandler!.value = () => {
  const bindings: Array<Field & { header: string; code: string | null; check: string }> = []
  for (let i = 0; i < matched.value.length; i++) {
    const match = matched.value[i]
    if (match.value) {
      bindings.push({ ...allFields.value[i], header: match.value })
    }
  }
  emit('bounded', bindings)
}
</script>

<style scoped>
:deep(.v-list-item) {
  cursor: grab;
}
:deep(.my-handle) {
  cursor: grabbing;
}
</style>
