<template>
  <template v-if="route.name === 'buildings'">
    <vis-loader :loading="buildings.isFetching">
      <v-row>
        <v-col sm="3" cols="12">
          <data-table-filter
            :columns="columns"
            :items="items"
            @update="filtered = $event"
            search-on-bottom
          />
        </v-col>
        <v-col sm="9" cols="12">
          <data-table
            :columns="columns"
            :items="filtered"
            :project="project.data!"
            v-on="{
              add: check(KeycloakScope.BUILDING_CREATE) ? onAdd : undefined,
              select: check(KeycloakScope.BUILDING_UPDATE) ? onSelect : undefined,
              delete: check(KeycloakScope.BUILDING_DELETE) ? onDelete : undefined
            }"
          >
            <template #actions.append="itemProps">
              <v-tooltip location="left">
                <template #activator="{ props }">
                  <vis-view-icon-btn
                    :link="true"
                    @click.stop="view(itemProps.item)"
                    class="ml-2"
                    v-bind="props"
                  />
                </template>
                <span>{{ $t('viewUnit') }}</span>
              </v-tooltip>

              <v-tooltip location="left">
                <template #activator="{ props }">
                  <vis-icon-btn
                    icon="mdi-application-brackets"
                    :link="true"
                    @click.stop="viewEmbed(itemProps.item)"
                    class="ml-2"
                    v-bind="props"
                  />
                </template>
                <span>{{ $t('viewUnit') }} (embed)</span>
              </v-tooltip>
            </template>

            <template #top>
              <span>
                <v-dialog max-width="500">
                  <template v-slot:activator="{ props: activatorProps }">
                    <v-tooltip location="left">
                      <template #activator="{ props: tooltipProps }">
                        <vis-icon-btn
                          icon="mdi-application-brackets"
                          v-bind="{ ...activatorProps, ...tooltipProps }"
                          class="mr-2"
                        >
                        </vis-icon-btn>
                      </template>
                      <span>Export embed urls</span>
                    </v-tooltip>
                  </template>

                  <template v-slot:default="{ isActive }">
                    <v-card title="Export Embed urls">
                      <v-card-text>
                        <div class="d-flex justify-center align-top">
                          <v-select
                            v-model="embedLanguage"
                            :label="`${$t('select')} ${$t('language')}`"
                            :items="
                              locales.map((locale) => ({
                                value: locale,
                                text: $t(`lang${locale.toUpperCase()}`)
                              }))
                            "
                            variant="outlined"
                            item-value="value"
                            item-title="text"
                            density="compact"
                          ></v-select>
                          <v-btn
                            color="primary"
                            prepend-icon="mdi-download"
                            text="download"
                            class="ml-2 text-capitalize"
                            @click="exportCSV(embedLanguage)"
                          />
                        </div>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn text="Close" @click="isActive.value = false"></v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
              </span>
            </template>
          </data-table>
        </v-col>
      </v-row>
    </vis-loader>
  </template>
  <template v-else>
    <router-view />
  </template>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import useBuildingStore from '@/stores/building'
import useProjectStore from '@/stores/project'
import useBuildingTableDefinitions from '@/composables/tableDefinitions/useBuildingTableDefinitions'
import useWatchFetchResult from '@/composables/useWatchFetchResult'
import DataTable from '@/components/dataTable/DataTable.vue'
import DataTableFilter from '@/components/dataTable/DataTableFilter.vue'
import type Building from '@/types/building'
import { useI18n } from 'vue-i18n'
import useScopeCheck from '@/composables/useScopeCheck'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'
import CsvExporter from '@/utils/CsvExporter'
import { locales } from '@/configs/i18n'

const check = useScopeCheck()
const route = useRoute()
const router = useRouter()
const { t } = useI18n()

const { project } = useProjectStore()

const { buildings, listBuildings, deleteBuilding } = useBuildingStore()
buildings.data || listBuildings()

const items = useWatchFetchResult(buildings, [])
const columns = computed(() => useBuildingTableDefinitions())
const filtered = ref<Array<Building>>([])

const onAdd = () => router.push({ name: 'createBuilding' })

const onSelect = (building: Building) =>
  router.push({
    name: 'updateBuilding',
    params: {
      buildingId: building.id
    }
  })

const onDelete = async (building: Building) => {
  if (confirm(t('areYouSureYouWantToDeleteTheBuilding', [building.id]))) {
    await deleteBuilding(building.id)
    listBuildings()
  }
}

const getBuildingFrontendUrl = (building: Building) => {
  const buildingSlug = building.slug
  const projectSlug = project.data!.slug
  const frontEndRoot = import.meta.env.VITE_FRONTEND_URL
  return `${frontEndRoot}/${projectSlug}/${buildingSlug}`
}

const view = (building: Building) => {
  const url = getBuildingFrontendUrl(building)
  window.open(url)
}

const getBuildingEmbedUrl = (building: Building) => {
  const buildingSlug = building.slug
  const projectSlug = project.data!.slug
  const playerRoot = import.meta.env.VITE_PLAYER_URL

  return `${playerRoot}&project=${projectSlug}&building=${buildingSlug}`
}

const viewEmbed = (building: Building) => {
  const url = getBuildingEmbedUrl(building)
  window.open(url)
}

const exportCSV = (language: string) => {
  let rows: any[] = []

  for (const building of filtered.value) {
    rows.push([building.name, `${getBuildingEmbedUrl(building)}&language=${language}`])
  }

  const exporter = new CsvExporter(
    rows,
    ['building', 'Embed Url'],
    `${project.data!.slug}-buildings-embed-urls`
  )
  exporter.exportCsvFile()
}

const embedLanguage = ref('nb')
</script>
