<template>
  <vis-loader type="list-item-avatar" :loading="images.isFetching">
    <v-container>
      <v-row>
        <v-col cols="12"> {{ label }}</v-col>
      </v-row>
      <v-row class="bg-grey-lighten-4 pa-5">
        <div class="d-flex align-center">
          <draggable
            :key="model.length"
            :list="model"
            :disabled="model.length === 1"
            class="d-flex flex-wrap"
            item-key="id"
            tag="div"
            @end="onDragEnd"
          >
            <template #item="{ element: imageId }">
              <div v-if="getImage(imageId)">
                <v-hover v-slot="{ isHovering, props }" :key="imageId">
                  <div class="ma-1 pa-2 bg-white position-relative cursor-pointer" v-bind="props">
                    <v-img
                      :src="`${project.data!.assetRoot}/images/${getImage(imageId)?.category}/${getImage(imageId)?.filename}`"
                      height="100"
                      width="100"
                      cover
                      @click="openPreview(getImage(imageId) as Image)"
                    />

                    <v-expand-transition>
                      <div
                        v-if="isHovering"
                        class="d-flex transition-fast-in-fast-out selected-image-hover-overlay position-absolute bottom-0 left-0 right-0"
                        style="height: 30%"
                      >
                        <v-btn
                          size="x-small"
                          variant="text"
                          color="error"
                          class="selected-image-delete-btn position-absolute"
                          @click="remove(imageId)"
                        >
                          {{ t('remove') }}
                        </v-btn>
                      </div>
                    </v-expand-transition>
                  </div>
                </v-hover>
              </div>
            </template>
          </draggable>

          <v-dialog v-model="dialog" transition="dialog-bottom-transition" fullscreen>
            <template v-slot:activator="{ props: activatorProps }">
              <div class="ma-1 pa-2 bg-white position-relative cursor-pointer">
                <div class="blank-image bg-grey display-inline-block">&nbsp;</div>
                <div
                  class="d-flex transition-fast-in-fast-out add-image-hover-overlay position-absolute bottom-0 left-0 right-0"
                  style="height: 100%"
                  v-bind="activatorProps"
                >
                  <v-btn
                    size="x-small"
                    :icon="multiple || model.length === 0 ? 'mdi-plus-thick' : 'mdi-pencil'"
                    class="selected-image-add-btn position-absolute"
                    :title="multiple ? t('addImages') : t('addOrUpdateImage')"
                  >
                  </v-btn>
                </div>
              </div>
            </template>

            <v-card>
              <v-toolbar color="primary">
                <v-btn icon="mdi-close" @click="dialog = false"></v-btn>

                <v-toolbar-title>{{ label }}</v-toolbar-title>

                <v-spacer></v-spacer>

                <v-toolbar-items>
                  <v-btn :text="t('done')" @click="dialog = false"></v-btn>
                </v-toolbar-items>
              </v-toolbar>
              <MediaGalleryView
                :preSelected="selectedImageIds"
                :multiple="multiple"
                :allowSelect="true"
                @onSelect="onSelect($event)"
                :category="category ? category : ProjectImagesCategory.ContentAssets"
              />
            </v-card>
          </v-dialog>
        </div>
      </v-row>
    </v-container>

    <v-dialog v-model="previewDialog" max-width="600">
      <div v-if="previewImage">
        <v-card class="mx-auto pb-10 align-center">
          <v-img
            :src="`${project.data!.assetRoot}/images/${previewImage.category}/${previewImage.filename}`"
            class="preview-dialog-image"
            contain
          />

          <v-card-title :title="previewImage.filename" class="mt-3"
            >{{ previewImage.filename }}
          </v-card-title>
          <v-card-subtitle> {{ previewImage.category }} </v-card-subtitle>

          <div class="close-btn position-absolute top-0 right-0">
            <v-btn size="x-small" icon="mdi-close" @click="closePreview()"></v-btn>
          </div>
        </v-card>
      </div>
    </v-dialog>
  </vis-loader>
</template>

<script setup lang="ts">
import draggable from 'vuedraggable'
import MediaGalleryView from './../../views/project/settings/MediaGalleryView.vue'
import useImageStore from '@/stores/image'
import useProjectStore from '@/stores/project'
import { computed, ref, watch, h } from 'vue'
import type Image from '@/types/image'
import { useI18n } from 'vue-i18n'
import { ProjectImagesCategory } from '@/types/image'

const { t } = useI18n()

const model = defineModel({ default: [] as Array<number> })
const {
  multiple = false,
  label,
  category
} = defineProps<{ label: string; multiple?: boolean; category?: ProjectImagesCategory }>()

const dialog = ref(false)

const { project } = useProjectStore()
const { images, listImages } = useImageStore()
images.data || listImages()

const selectedImageIds = computed(() => {
  return model.value
})

const onSelect = (images: Array<Image>) => {
  if (multiple) {
    model.value = images.map((i) => i.id)
  } else {
    if (images.length > 0) {
      model.value = [images[0].id]
      return
    }
    model.value = []
  }
}

const remove = async (imageId: number) => {
  model.value = model.value.filter((i) => i !== imageId)
}

const onDragEnd = (e: any) => {}

const getImage = computed(() => {
  return (id: number) => images.data!.find((i) => i.id === id)
})

const previewDialog = ref(false)
const previewImage = ref<Image | null>(null)
const openPreview = (image: Image) => {
  previewImage.value = image
  previewDialog.value = true
}

const closePreview = () => {
  previewImage.value = null
  previewDialog.value = false
}
</script>
<style scoped>
.selected-image-delete-btn,
.selected-image-add-btn {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.selected-image-hover-overlay,
.selected-add-hover-overlay {
  background-color: rgba(0, 0, 0, 0.8);
}

.blank-image {
  width: 100px;
  height: 100px;
}

.preview-dialog-image {
  height: 70vh;
  width: auto;
}
</style>
